import dayjs from 'dayjs';

import { CityType } from 'types/types';

export const adjustHeightOverflow = (
  modalHeight: number,
  initialTopPosition: number,
) => {
  const initialHeight = modalHeight + initialTopPosition;
  if (initialHeight > window.innerHeight) {
    // console.log(' OVERFLOW')

    return initialHeight - window.innerHeight;
  }
  // console.log('NO OVERFLOW')
  return 0;
};
export function filterAndIndex(
  array: Array<any>,
  attrValue: any,
  attrName: string,
) {
  let element: any;
  let index = -1;
  //console.log('ar', array)
  for (let i = 0; i < array.length; i++) {
    //console.log(array[i])
    if (array[i][attrName] === attrValue) {
      element = array[i];
      index = i;
      break;
    }
  }
  return { element, index };
}
export function formattedTime(currentTime: any) {
  if (currentTime) {
    const date = new Date(0);
    date.setSeconds(currentTime);
    const ms = Math.round((currentTime % 1) * 1000);
    date.setMilliseconds(currentTime);
    // console.log('iso', dayjs(date).toISOString())
    return `${dayjs(date).second()}.${ms.toString().padStart(3, '0')}`;
  }
  return '00:00';
}
export function formattedTimeSeconds(currentTime: any) {
  // console.log(currentTime);
  if (currentTime) {
    const hours = Math.floor(currentTime / 60 / 60);
    const minutes = Math.floor(currentTime / 60 - hours * 60);
    const seconds = Math.floor(currentTime) - hours * 60 * 60 - minutes * 60;
    return `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return '0:00:00';
}
export const isCityType = (obj: any): obj is CityType => {
  return true;
};
export const constructTimeZoneName = (obj: CityType) => {
  // console.log('obj', obj)
  const offset = obj.gmt_offset / 60 / 60;
  return `${obj.city ? obj.city : obj.name.split('/').slice(1)} (${obj.country_code}) (UTC ${offset > 0 ? '+' : ''}${offset})`;
};
export const parseDayFromApi = (apiEvent: any): any => {
  const d = dayjs(apiEvent.started_at);
  let dFinish;
  if (apiEvent.finished_at) {
    dFinish = dayjs(apiEvent.finished_at);
  }
  return {
    day: d.date(),
    month: d.month(),
    year: d.year(),
    startHour: d.hour(),
    startMinute: d.minute(),
    finishHour: dFinish?.hour(),
    finishMinute: dFinish?.minute(),
    started_at: apiEvent.started_at,
    finished_at: apiEvent.finished_at,
    time_zone: apiEvent.time_zone,
  };
};
export function isType<T>(variable: any): variable is T {
  // Get the keys of type T dynamically
  // const keys = Object.keys({} as T) as (keyof T)[];
  // type KeysOf<T> = T extends object ? Array<keyof T> : never;
  const keys = Object.keys(variable) as Array<keyof T>;

  // const keys: KeysOf<T>[] = Object.keys(variable) as KeysOf<T>[];
  // const keys: KeysOf<T> = Object.keys(variable);

  // Check if all keys are present in the variable
  return (
    typeof variable === 'object' &&
    variable !== null &&
    keys.every((key) => key in variable)
  );
}
export function formatMillisecondsToTime(milliseconds: number, withMs = false) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const ms = Math.round((milliseconds / 1000 - totalSeconds) * 100);

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedMiliseconds = String(ms).padStart(2, '0').slice(0, 2);
  if (withMs) {
    return `${formattedMinutes}:${formattedSeconds}.${formattedMiliseconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
export function formatMillisecondsToTimeWithUndefined(
  milliseconds: number | undefined,
  withMs = false,
) {
  if (milliseconds !== undefined) {
    return formatMillisecondsToTime(milliseconds, withMs);
  }
}
export function formatMillisecondsToTimeWithHours(milliseconds: number) {
  // console.log('milliseconds', milliseconds)
  const totalSeconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;
  const seconds = totalSeconds % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedHours = String(hours).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export function convertTimeToMilliseconds(
  timeString: string,
  toSeconds = false,
) {
  const [minutes, seconds] = timeString.split(':');
  const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds);
  if (toSeconds) {
    return totalSeconds;
  }
  return totalSeconds * 1000;
}
export function convertTimeWithHoursToMilliseconds(
  timeString: string,
  toSeconds = false,
) {
  const [hours, minutes, seconds] = timeString.split(':');
  const totalSeconds =
    parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds);
  if (toSeconds) {
    return totalSeconds;
  }
  return totalSeconds * 1000;
}
export const constructTimeWithZoneShift = (evt: any) => {
  const offset = dayjs().tz(evt.time_zone).utcOffset() / 60;
  return `${evt.date.startHour}:${evt.date.startMinute.toString().padStart(2, '0')} (UTC${offset >= 0 ? '+' : ''}${offset})`;
};
export const parseTimeStringToTime = (timeString: string) => {
  return dayjs(timeString, 'HH:mm:ss');
};
export const formatDatetimeToDate = (datetime: string | null | undefined) => {
  if (datetime) {
    return dayjs(datetime).format('DD.MM.YYYY');
  }
};
export const validateValue = (s: string) => {
  if (s.length === 5) {
    return s
      .split('')
      .map((char: string, index: number) => {
        if (index < 2) {
          return char;
        } else if (index === 2) {
          return null;
        } else if (index === 3 && Number(char) > 5) {
          return ':5';
        } else if (index === 3 && Number(char) <= 5) {
          return ':' + char;
        } else {
          return char;
        }
      })
      .join('');
  }
  if (s.length === 6) {
    return s
      .split('')
      .map((char: string, index: number) => {
        if (index < 3) {
          return char;
        } else if (index === 3) {
          return null;
        } else if (index === 4 && Number(char) > 5) {
          return ':5';
        } else if (index === 4 && Number(char) <= 5) {
          return ':' + char;
        } else {
          return char;
        }
      })
      .join('');
  }
  return '';
};
export const handleVideoTimeInputChange = (event: any, callback: any) => {
  const { value } = event.target;
  const newValue = value.replace(/\D/g, '').slice(0, 5);
  let formattedValue;
  if (newValue.length <= 4) {
    formattedValue = newValue
      .split('')
      .map((char: string, index: number) => (index === 2 ? ':' + char : char))
      .join('');
  } else {
    formattedValue = newValue
      .split('')
      .map((char: string, index: number) => (index === 3 ? ':' + char : char))
      .join('');
  }
  callback(formattedValue);
};
