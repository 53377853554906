import React from 'react';

import { Checkbox, ConfigProvider, Flex } from 'antd';

const MetricFilter = ({
  metric,
  selectedMetricFilters,
  hoveredMetricFilter,
  toggleSelectedMetric,
}: {
  metric: string;
  selectedMetricFilters: any;
  hoveredMetricFilter: any;
  toggleSelectedMetric: any;
}) => {
  const metricStringSuccess = `${metric}_success`;
  const metricStringFail = `${metric}_fail`;
  if (
    (selectedMetricFilters &&
      (selectedMetricFilters.includes(metricStringSuccess) ||
        selectedMetricFilters.includes(metricStringFail))) ||
    hoveredMetricFilter.includes(metric)
  ) {
    return (
      <Flex gap={4}>
        <Flex className="success-checkbox" justify="center">
          <ConfigProvider
            theme={{
              token: {},
            }}
          >
            <Checkbox
              checked={selectedMetricFilters.includes(metricStringSuccess)}
              onClick={() => {
                toggleSelectedMetric(metricStringSuccess, metricStringFail);
              }}
            />
          </ConfigProvider>
        </Flex>
        <Flex className="fail-checkbox" justify="center">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: 'grey',
              },
            }}
          >
            <Checkbox
              checked={selectedMetricFilters.includes(metricStringFail)}
              onClick={() => {
                toggleSelectedMetric(metricStringFail, metricStringSuccess);
              }}
            />
          </ConfigProvider>
        </Flex>
      </Flex>
    );
  } else {
    return <Flex children={undefined}></Flex>;
  }
};
export default MetricFilter;
