/* eslint-disable indent */
import axios from 'axios';
import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import { makeStatsPlaceholders } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { ActivityType } from 'types/playlist.types';

const {
  setTeamPlayerStatsState,
  setTeamPlayerMatches,
  setSelectedLastMatch,
  setSelectedMatches,
  setTeamPlayerStats,
  setStatsState,
  setTableMatches,
  setSelectedRowKeys,
  setCheckedSeasons,
  setAppliedSeasons,
  setExpandedSeasons,
  setSelectedTournaments,
  setTimeOnFieldByPosition,
  setTimeOnField,
  setLoadingPercentiles,
} = teamPlayerStatsReducer.actions;

const {
  setPositions,
  setPositionsDurations,
  setSelectedPosition,
  setPositionsLoading,
} = teamPlayerReducer.actions;
export const SetStatsState =
  (
    state: 'init' | 'loading' | 'loaded' | 'error',
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatsState(state));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const GetPersonaAllMatches =
  (
    playerId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setTeamPlayerStatsState('loading'));
      try {
        const data = await EventsAPI.getTeamPlayerGamesWithSeasons(
          playerId,
          true,
        );
        if (data.data) {
          dispatch(setTeamPlayerMatches(data.data));
          if (data.data.length > 0) {
            const firstSeasonMatchIds = data.data[0]['leagues'][0][
              'matches'
            ].map((match: any) => match.id);
            dispatch(
              setTableMatches(
                data.data[0]['leagues'][0]['matches'].map((match: any) => {
                  return { key: match.id, gameDetails: match };
                }),
              ),
            );
            // alert('selectFirstSeason');
            dispatch(setSelectedRowKeys(firstSeasonMatchIds));
            dispatch(
              setCheckedSeasons([
                `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
              ]),
            );
            dispatch(
              setSelectedTournaments([
                `1-${data.data[0].name}-${data.data[0].leagues[0]?.tournament_id}`,
              ]),
            );
            dispatch(setSelectedMatches(data.data[0]['leagues'][0]['matches']));
            dispatch(setExpandedSeasons([`0-${data.data[0].name}`]));
            dispatch(setAppliedSeasons(data.data[0].leagues[0]?.season_id));
          }
        }
        dispatch(setTeamPlayerStatsState('loaded'));
      } catch (e) {
        dispatch(setTeamPlayerStatsState('error'));
      }
    } catch (e) {
      dispatch(setTeamPlayerStatsState('error'));
    }
  };

export const SetSelectedLastMatch =
  (
    count: number | string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedLastMatch(count));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const SelectGame =
  (
    match: ActivityType,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    try {
      const [selectedMatches, checkedSeasons] =
        getState().teamPlayerStatsReducer.selectedMatches;
      if (selectedMatches.includes(match)) {
        dispatch(
          setCheckedSeasons(checkedSeasons.filter((key: string) => key !== '')),
        );
        dispatch(
          setSelectedMatches(
            selectedMatches.filter((item: any) => item !== match),
          ),
        );
      } else {
        dispatch(setSelectedMatches([...selectedMatches, match]));
      }
    } catch (e) {
      console.error('first error', e);
    }
  };

export const ClearSelectedGames =
  (): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedMatches([]));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const FilterApply =
  (
    t: any,
    checkedSeason: string,
    selectedMatchesIds: any[],
    playerId: string,
    abortSignal: AbortSignal,
  ): ThunkAction<Promise<any>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    dispatch(setStatsState('loading'));
    if (!playerId) {
      playerId = getState().teamPlayerReducer.openedTeamPlayer?.id;
    }
    try {
      console.log('FilterApply');
      let tournamentIdForFilter;
      const { selectedPosition } = getState().teamPlayerReducer;
      const response = await EventsAPI.getPlayerGamesStatsByPost(
        checkedSeason,
        playerId,
        selectedPosition || '',
        tournamentIdForFilter,
        selectedMatchesIds,
        false,
        abortSignal,
      );
      const finalStats: any = [];
      const placeholder = makeStatsPlaceholders(t);
      placeholder.forEach((stat) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          excluded_sports: stat.excluded_sports,
          for_sport: stat.for_sport,
          stat: response?.data?.stats.filter(
            (s: any) => s.key === stat?.key,
          )[0],
        };
        if (stat.children && stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              excluded_sports: childStat.excluded_sports,
              for_sport: childStat.for_sport,
              stat: response.data.stats.filter(
                (s: any) => s.key === childStat.key,
              )[0],
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      dispatch(setTeamPlayerStats(finalStats));
      dispatch(setTimeOnField(response.data.time_on_field));
      dispatch(
        setTimeOnFieldByPosition(
          response.data.stats.filter((s: any) => {
            return s.key === 'played_time';
          })[0]?.by_position,
        ),
      );
      await dispatch(
        GetPlayerPositions(t, checkedSeason, selectedMatchesIds, abortSignal),
      );
      if (checkedSeason !== 'manual') {
        await dispatch(GetSeasonPercentiles(playerId, checkedSeason));
      }
      dispatch(setStatsState('loaded'));
    } catch (e) {
      dispatch(setStatsState('error'));
      console.error('first error', e);
    }
  };

export const GetSeasonPercentiles =
  (
    playerId: string,
    seasonId: string,
  ): ThunkAction<Promise<any>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { selectedPosition, positionsDurations } =
      getState().teamPlayerReducer;
    const { teamPlayerStats } = getState().teamPlayerStatsReducer;
    if (playerId && seasonId && seasonId !== 'manual') {
      // clear
      let finalStats: any = [];
      teamPlayerStats.forEach((stat) => {
        const populatedStat: any = {
          ...stat,
          stat: {
            ...stat.stat,
            pth: null,
          },
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              stat: {
                ...childStat.stat,
                pth: null,
              },
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      if (
        selectedPosition &&
        positionsDurations[selectedPosition] > 90 * 60 * 1000
      ) {
        const source = axios.CancelToken.source();
        dispatch(setLoadingPercentiles(true));
        const response = await EventsAPI.getPlayerPercentilesBySeason(
          seasonId,
          playerId,
          selectedPosition,
          [],
          selectedPosition
            ? positionsDurations[selectedPosition] > 90 * 60 * 1000
            : false,
          source.token,
        );
        if (response?.data) {
          finalStats = [];
          teamPlayerStats.forEach((stat) => {
            const populatedStat: any = {
              ...stat,
              stat: {
                ...stat.stat,
                pth: response.data.stats?.filter(
                  (s: any) => s.key === stat?.key,
                )[0],
              },
            };
            if (stat.children?.length > 0) {
              populatedStat.children = [];
              stat.children.forEach((childStat: any) => {
                const s = response.data.stats.filter(
                  (s: any) => s.key === childStat.key,
                )[0];
                const childPopulatedStat = {
                  ...childStat,
                  stat: {
                    ...childStat.stat,
                    pth: s,
                  },
                };
                populatedStat.children.push(childPopulatedStat);
              });
            }
            finalStats.push(populatedStat);
          });
          dispatch(setLoadingPercentiles(false));
          dispatch(setTeamPlayerStats(finalStats));
        } else {
          dispatch(setLoadingPercentiles(false));
        }
        return () => {
          source.cancel('Operation canceled by the user.');
        };
      } else {
        dispatch(setLoadingPercentiles(false));
        dispatch(setTeamPlayerStats(finalStats));
      }
    }
  };

export const GetSingleSeasonSelected =
  (): ThunkAction<Promise<string>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { teamPlayerStatsMatches, selectedRowKeys } =
      getState().teamPlayerStatsReducer;
    let seasonId = 'manual';
    let found = true;
    selectedRowKeys !== undefined &&
      teamPlayerStatsMatches.forEach((season: any) => {
        if (!found) {
          return;
        }
        season.leagues.forEach((league: any) => {
          if (league.matches.length === selectedRowKeys.length) {
            const leagueIds = league.matches
              .map((leagueMatch: any) => leagueMatch.id)
              .sort();
            const tableMatchesIds = selectedRowKeys.map((el) => el).sort();
            const e = leagueIds.every((value: string, index: number) => {
              return value === tableMatchesIds[index];
            });
            console.log('league');
            console.log(league);
            console.log(e);
            if (e) {
              found = false;
              seasonId = league.season_id;
              return;
            }
          }
        });
      });
    return seasonId;
  };

export const GetPlayerPositions =
  (
    t: any,
    checkedSeason: string,
    selectedMatchesIds: any[],
    signal: AbortSignal,
  ): ThunkAction<Promise<any>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    const { selectedPosition } = getState().teamPlayerReducer;
    const { openedTeamPlayer } = getState().teamPlayerReducer;
    dispatch(setPositionsLoading(true));
    const response = await PlayersAPI.getPlayerRoles(
      openedTeamPlayer.id,
      selectedPosition || '',
      checkedSeason,
      undefined,
      selectedMatchesIds,
      signal,
    );
    const allPositions = [
      ...response.data.roles.map((role: string) => {
        return { value: role, label: t(role) };
      }),
    ];
    if (selectedPosition && !response.data.roles.includes(selectedPosition)) {
      dispatch(setSelectedPosition(null));
    }
    dispatch(setPositions(allPositions));
    dispatch(setPositionsDurations(response.data.durations));
    dispatch(setPositionsLoading(false));
  };
